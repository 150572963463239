const EventEmitter = require('./eventemitter');
const util = require('./util');

/**
 * @template S search
 */
class Page extends EventEmitter {
	constructor() {
		super();

		this.$container = $('.ui-container');

		$(window).on('keydown keyup blur', ev => {
			this.altKey = !!ev.altKey;
			this.shiftKey = !!ev.shiftKey;
			this.ctrlKey = !!ev.ctrlKey;
			this.metaKey = !!ev.metaKey;
		});

		$(window).focus(() => this.emit('focus'));
		$(window).blur(() => this.emit('blur'));

		this.name = Ingtech.requestedPage;
		this.options = Ingtech.pageOptions;

		if (Ingtech.Storage) {
			this.cookieStorage = new Ingtech.Storage(Ingtech.requestedPage, { storage: 'cookie' });
			this.cookie = this.cookieStorage.proxy();

			this.sessionStorage = new Ingtech.Storage(Ingtech.requestedPage, { storage: 'session' });
			this.session = this.sessionStorage.proxy();
		}

		this.checkMaintenance();
	}

	get isActive() {
		return !document.hidden;
	}

	merge(page) {
		let events = this.events;

		const options = this.options;
		Object.assign(this, page);
		this.options = Object.assign(options, page.options || {});

		for (let [event, cbs] of Object.entries(events)) {
			this.events[event] = this.events[event].concat(cbs);
		}

		return this;
	}

	/**
	 * 
	 * @param {string} anchor 
	 */
	setAnchor(anchor) {
		anchor = anchor ? '#' + anchor : '';

		history.replaceState(null, null, `${location.pathname}${location.search}${anchor}`);
	}

	/**
	 * @returns {string}
	 */
	getAnchor() {
		return location.hash.substring(1);
	}

	/**
	 * @overload
	 * @param {object} search 
	 *//**
	 * @overload
	 * @param {null | undefined} search 
	 *//**
	 * @template {keyof S} K
	 * @overload
	 * @param {K} key 
	 * @param {S[K]} value 
	 */
	setSearch(search, value) {
		if (typeof search === 'object') {
			history.replaceState(null, null, `${location.pathname}?${util.encodeObject(search)}${location.hash}`);
		} else if (typeof search === 'string') {
			this.setSearchProp(search, value);
		} else if (search == null || search == undefined) {
			this.clearSearch();
		}
	}

	/**
	 * @template {keyof S} K
	 * @param {K} key 
	 * @param {S[K]} value 
	 */
	setSearchProp(key, value) {
		const search = this.getSearch();
		search[key] = value;
		this.setSearch(search);
	}

	clearSearch() {
		history.replaceState(null, null, `${location.pathname}${location.hash}`);
	}

	/**
	 * @overload
	 * @returns {S}
	 *//**
	 * @template {keyof S} K
	 * @overload
	 * @param {K} key
	 * @returns {S[K]}
	 */
	getSearch(key) {
		if (key) {
			return this.getSearchProp(key);
		} else {
			return util.searchObject();
		}
	}

	/**
	 * @template {keyof S} K
	 * @param {K} key
	 * @returns {S[K]}
	 */
	getSearchProp(key) {
		return this.getSearch()[key];
	}

	checkMaintenance() {
		if (!Ingtech.webSettings?.web) return;
		const message = Ingtech.webSettings?.web['maintenance.message.popup'];

		if (!message) {
			return;
		}
		if (Ingtech.session.maintenanceSeen) {
			return;
		}

		Ingtech.session.maintenanceSeen = true;

		eModal.alert({
			message: i18n.description(message),
			title: 'Maintenance',
			size: 'lg',
			buttons: [{ text: i18n.__CLOSE, close: true }]
		});
	}
}

Ingtech.page = new Page();
Ingtech.Page = Page;

module.exports = Page;